import React from 'react';
import SEO from "../components/seo"
import { Footer, Header, Page, Container } from '@components';
import LearnAndEarn from '@sections/LearnAndEarn';

const EducationPage = () => (
  <Page>
    <SEO
      title="Learn & earn"
      description="A backend hands-on course organised by Dream Factory. 5 students, 12 weeks, 33 kn/h"
    />
    <Header />
    <Container type="small">
      <LearnAndEarn />
    </Container>
    <Footer />
  </Page>
)

export default EducationPage